import './bar-chart.scss';
import { memo, useEffect, useState } from 'react';
import {
  Bar,
  BarChart as RechartBarChart,
  CartesianGrid,
  Cell,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';

import { bemCN } from '../../configs/bem-classname';
import { ChartDataItem } from '../../view-models/chart-data-item';
import { Tooltip as CustomTooltip } from '../tooltip/tooltip';

export enum ColorsBarChart {
  colorful,
  blue,
  green,
  red,
  orange,
}

type BarChartProps = {
  id?: string;
  color: ColorsBarChart;
  data: ChartDataItem[];
  labelY?: string;
  labelX?: string;
  unit?: string;
  isNormalLabel?: boolean;
  isShortLable?: boolean;
  isLongYData?: boolean;
  isInt?: boolean;
  errorText?: string;
  height?: number;
};

const colors = new Map<string, string[]>([
  ['red', ['rgba(193, 75, 75, 0.8)', 'rgba(193, 75, 75, 0.25)']],
  ['orange', ['rgba(198, 140, 71, 0.8)', 'rgba(198, 140, 71, 0.25)']],
  ['yellow', ['rgba(206, 208, 85, 0.8)', 'rgba(206, 208, 85, 0.25)']],
  ['green', ['rgba(90, 180, 62, 0.8)', 'rgba(90, 180, 62, 0.25)']],
  ['lightBlue', ['rgba(65, 188, 168, 0.8)', 'rgba(65, 188, 168, 0.25)']],
  ['blue', ['rgba(81, 149, 191, 0.8)', 'rgba(81, 149, 191, 0.25)']],
  ['violet', ['rgba(87, 92, 194, 0.8)', 'rgba(87, 92, 194, 0.25)']],
]);

export const BarChart = memo((props: BarChartProps) => {
  let {
    labelY,
    data,
    isNormalLabel,
    color,
    labelX,
    isLongYData,
    errorText,
    isInt,
    isShortLable,
    height,
    unit,
  } = props;

  const colorType = ColorsBarChart[color];
  let colorGradient;
  let idGradient = '';
  let isColoful = false;

  if (colorType == 'colorful') {
    colorGradient = colorType ? colors.get('red') : colors.get('blue');
    idGradient = colorType ? colorType : 'blue';
    isColoful = true;
  } else {
    colorGradient = colorType ? colors.get(colorType) : colors.get('blue');
    idGradient = colorType ? colorType : 'blue';
  }

  const keysColor = Array.from(colors.keys());
  const valueColor = Array.from(colors.values());
  let countBar = 1;
  let indexColorType = 0;

  const customBarChartCN = bemCN('barChart');

  return (
    <div className={customBarChartCN()}>
      {data.length === 0 ? (
        <div className={customBarChartCN('error')}>{errorText}</div>
      ) : (
        <ResponsiveContainer width="100%" height={height ? height : 278}>
          <RechartBarChart
            width={854}
            height={578}
            data={data}
            margin={{
              top: 20,
              right: 0,
              left: labelY ? 50 : 10,
              bottom: isNormalLabel ? 5 : 50,
            }}
          >
            <CartesianGrid vertical={false} />
            <defs>
              <linearGradient id={idGradient} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colorGradient && colorGradient[0]} />
                <stop offset="95%" stopColor={colorGradient && colorGradient[1]} />
              </linearGradient>
              {keysColor.map((key, index) => (
                <linearGradient id={key} key={index} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={valueColor[index][0]} />
                  <stop offset="95%" stopColor={valueColor[index][1]} />
                </linearGradient>
              ))}
            </defs>

            {!isNormalLabel ? (
              <XAxis
                dy={35}
                dx={-25}
                angle={-45}
                // interval={0}
                style={{
                  fontSize: '16px',
                  fontFamily: 'Manrope',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fill: 'var(--font-color)',
                }}
                axisLine={false}
                dataKey={isShortLable ? 'shortLabel' : 'label'}
                //interval={2}
              />
            ) : (
              <XAxis
                dy={15}
                // interval={0}
                style={{
                  fontSize: '14px',
                  fontFamily: 'Manrope',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fill: 'var(--font-color)',
                }}
                axisLine={false}
                dataKey={isShortLable ? 'shortLabel' : 'label'}

                //interval={1}
              />
            )}
            <YAxis
              width={labelY ? 40 : 35}
              style={{
                fontSize: '14px',
                fontFamily: 'Manrope',
                fontStyle: 'normal',
                fontWeight: '400',
                fill: 'var(--bar-chart-y-axis-label)',
              }}
              type="number"
              axisLine={false}
              allowDecimals={isInt ? false : true}
            >
              <Label
                offset={15}
                dy={isLongYData ? -105 : -70}
                dx={isLongYData ? -30 : -10}
                value={labelY}
                angle={-90}
                position={'left'}
                style={{
                  fontSize: '16px',
                  fontFamily: 'Manrope',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '28px',
                  fill: 'var(--bar-chart-y-axis-label)',
                }}
              />
            </YAxis>
            <Tooltip content={<CustomTooltip unit={unit} />} cursor={{ fill: 'transparent' }} />

            {isColoful && idGradient ? (
              <Bar
                activeBar={{ stroke: valueColor[indexColorType][1], strokeWidth: 1 }}
                dataKey="value"
                radius={[5, 5, 0, 0]}
                maxBarSize={20}
              >
                {data.map((item, index) => {
                  if (countBar % 3 == 0) {
                    indexColorType++;
                  }
                  if (indexColorType > keysColor.length - 1) {
                    indexColorType = 0;
                  }
                  countBar++;

                  return <Cell fill={`url(#${keysColor[indexColorType]})`} key={`cell-${index}`} />;
                })}
              </Bar>
            ) : data[0].color == undefined ? (
              <Bar
                activeBar={{ stroke: 'rgba(65, 188, 168, 0.8)', strokeWidth: 1 }}
                dataKey="value"
                fill={`url(#${idGradient})`}
                maxBarSize={20}
                radius={[5, 5, 0, 0]}
              />
            ) : (
              <Bar
                activeBar={{ stroke: valueColor[indexColorType][1], strokeWidth: 1 }}
                dataKey="value"
                radius={[5, 5, 0, 0]}
                maxBarSize={20}
              >
                {data.map((item, index) => {
                  return (
                    <Cell
                      fill={`url(#${ColorsBarChart[item.color as ColorsBarChart]})`}
                      key={`cell-${index}`}
                    />
                  );
                })}
              </Bar>
            )}

            {/* <Bar
            activeBar={{ stroke: 'rgba(65, 188, 168, 0.8)', strokeWidth: 1 }}
            dataKey="value"
            fill={`url(#${idGradient})`}
            radius={[5, 5, 0, 0]}
          /> */}
          </RechartBarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
});

export default BarChart;
