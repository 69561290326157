import { bemCN } from '../../configs/bem-classname';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { ReactComponent as FilterIcon } from '../../shared/image/svg/filter.svg';
import { ReactComponent as ColumnsIcon } from '../../shared/image/svg/columns.svg';
import './sellers-page.scss';
import { Pagination } from '../../components/pagination/pagination';
import { Table } from '../../ui/table/table';
import { ColumnsBar } from '../../components/columns-bar/columns-bar';
import { useEffect, useState } from 'react';
import { BarPanel } from '../../components/bar-panel/bar-panel';
import { TableColumn } from '../../view-models/table-column';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';
import { FilterBar } from '../../components/filter-bar/filter-bar';
import { SellerFilter } from '../../components/filters/sellers-filter/sellers-filter';
import { InfoTable } from '../../components/info-table/info-table';
import { sellersInfoTableColumns } from '../../consts/info-tables-columns';
import { sellersService } from '../../services/sellers-service';
import { defaultSellerStatFilter } from '../../consts/default-filter-values';
import { PieChart } from '../../ui/pie-chart/pie-chart';
import { ChartDataItem } from '../../view-models/chart-data-item';
import { infoTableColors } from '../../consts/info-table-colors';
import { SellerDto, SellerStatisticsDto } from '../../api/Api';
import BigLoader from '../../components/big-loader/big-loader';
import { SellerFilterDto } from '../../view-models/seller-filter-dto';
import { SearchInput } from '../../components/search-input/search-input';
import { linksUrl } from '../../consts/linksUrl';
import { useTable } from '../../hooks/useTable';
import { Export } from '../../components/export/export';
import { TypeFile } from '../../view-models/type-files';
import { LineLoading } from '../../components/line-loading/line-loading';
import { isOnlyDigit } from '../../utils/isOnlyDigit';
import { TableLoad } from '../../components/table-load/table-load';
import { MerticNames, sendMetrics } from '../../utils/metrics';

const sellersPageCn = bemCN('sellers-page');

export const SellersPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSellersLoading, setIsSellersLoading] = useState(false);

  const [isShowColumnsBar, setIsShowColumnsBar] = useState(false);
  const [isShowFilterBar, setIsShowFilterBar] = useState(false);
  const [tableColumns, setTableColumns] = useState<TableColumn[]>(
    localStorageService.getValue(LocalStorageVariableName.sellerColumn) || [],
  );
  const [sellersStat, setSellerStat] = useState<SellerStatisticsDto[]>([]);
  const [filterSellers, setFilterSellers] = useState<SellerFilterDto>({
    ...defaultSellerStatFilter,
  });
  const [sellers, setSellers] = useState<SellerDto[]>([]);

  const [isLoadingFileExport, setIsLoadingFileExport] = useState(false);

  const [countSellers, setCountSellers] = useState<number>();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    let startFilter = getParams();
    tableColumns.length == 0 &&
      sellersService.getColumns().then((res: TableColumn[]) => setTableColumns(res));
    getSellers(startFilter);
    getSellersStat(startFilter);
  }, [1]);

  //Сброс фильтров
  const onResetFilter = () => {
    saveParams(defaultSellerStatFilter);
    setFilterSellers(defaultSellerStatFilter);
    getSellers(defaultSellerStatFilter);
    getSellersStat(defaultSellerStatFilter);
    setSearchValue('');
    setIsShowFilterBar(false);
  };

  //Применить фильтры
  const onApplyFilter = () => {
    saveParams({ ...filterSellers, offset: 0 });
    getSellers({ ...filterSellers, offset: 0 });
    getSellersStat({ ...filterSellers, offset: 0 });
    setIsShowFilterBar(false);
  };

  //Получение продавцов
  const getSellers = (filter: SellerFilterDto) => {
    // setIsLoading(true);
    setIsSellersLoading(true);
    sellersService.getSellers(filter).then((res: any) => {
      setSellers(res);
      setIsSellersLoading(false);
    });
    sellersService.getSellerCount(filter).then((res: number) => setCountSellers(res));
  };

  //Получение данныех для статистики
  const getSellersStat = async (filter: SellerFilterDto) => {
    setSellerStat([]);
    sellersService.getSellersStat(filter).then((res: SellerStatisticsDto[]) => {
      setSellerStat(res);
      setIsLoading(false);
    });
  };

  //Подключение инструментов для работы с таблицей
  const { onPagination, onSort, getParams, saveParams, onChangeDate, pageNumber, setPageNumber } =
    useTable(
      countSellers || 0,
      filterSellers,
      defaultSellerStatFilter,
      setFilterSellers,
      getSellers,
    );

  //Обработка поиска
  const onSearchInputEnter = () => {
    onApplyFilter();
  };

  //Клик на экспорт
  const onClickExport = (typeFile: TypeFile) => {
    setIsLoadingFileExport(true);
    sellersService
      .startExportFile(
        filterSellers,
        typeFile,
        tableColumns.map((col: TableColumn) => col.id),
      )
      .then((res: number) => {
        setIsLoadingFileExport(false);
        sellersService.getFile(res.toString(), typeFile);
      });
  };

  const onChangeSearchValue = (v: string) => {
    if (isOnlyDigit(v)) {
      setFilterSellers({ ...filterSellers, inn: v });
    } else {
      setFilterSellers({ ...filterSellers, name: v });
    }
    setSearchValue(v);
  };
  if (isLoading) return <BigLoader color="dark" />;

  return (
    <div className={sellersPageCn()}>
      <div
        data-title={
          'Все указанные расчетные данные представлены за период с 01.01.2023 по настоящее время'
        }
      >
        <Button color={ColorsButton.white} size={SizesButton.big} text="За все время" noDisabled />
      </div>
      <div>
        <div>
          <InfoTable
            linkTemplate={linksUrl.seller}
            data={sellersStat}
            columns={sellersInfoTableColumns}
          />
        </div>
        <PieChart
          data={sellersStat.map(
            (item: SellerStatisticsDto, index: number) =>
              new ChartDataItem(
                item.id || index,
                item.name || '',
                item.revenueSum || -1,
                infoTableColors[index],
              ),
          )}
          errorText={'Не удалось загрузить данные'}
        />
      </div>

      <div className={sellersPageCn('title-panel')}>
        <p className="h1">Список продавцов</p>
        <SearchInput
          value={searchValue}
          onChange={onChangeSearchValue}
          onEnterClick={onSearchInputEnter}
          placeholder="Поиск по продавцам"
        />
      </div>
      <div className={sellersPageCn('buttons')}>
        <Button
          color={ColorsButton.white}
          size={SizesButton.big}
          text="Фильтры"
          icon={<FilterIcon />}
          onClick={() => {
            setIsShowColumnsBar(false);
            setIsShowFilterBar(!isShowFilterBar);
          }}
        />
        <Export onClickExport={onClickExport} />

        <Button
          color={ColorsButton.white}
          size={SizesButton.big}
          text="Выбрать колонки"
          icon={<ColumnsIcon />}
          onClick={() => {
             sendMetrics('reachGoal', MerticNames.checkColumnsButtonClick);
            setIsShowFilterBar(false);
            setIsShowColumnsBar(!isShowColumnsBar);
          }}
        />
        <Pagination
          currentPageNumber={pageNumber}
          totalPagesCount={countSellers ? Math.ceil(countSellers / 50) : 1}
          onClick={onPagination}
        />
      </div>

      {tableColumns.length > 0 && (
        <>
          {isSellersLoading ? (
            <TableLoad />
          ) : (
            <>
              <Table
                localStorageColumns={LocalStorageVariableName.sellerColumn}
                columns={tableColumns}
                data={sellers}
                onSort={onSort}
                idActiveSort={filterSellers.sortFieldId || 666}
                decs={filterSellers.desc || false}
              />
              <Pagination
                currentPageNumber={pageNumber}
                totalPagesCount={countSellers ? Math.ceil(countSellers / 50) : 1}
                onClick={onPagination}
              />
            </>
          )}

          <BarPanel
            title="Выбрать колонки"
            isShow={isShowColumnsBar}
            setIsShow={setIsShowColumnsBar}
          >
            <ColumnsBar
              localStorageColumns={LocalStorageVariableName.sellerColumn}
              setColumns={setTableColumns}
              columns={tableColumns}
            />
          </BarPanel>
          <BarPanel title="Фильтры" isShow={isShowFilterBar} setIsShow={setIsShowFilterBar}>
            <FilterBar onApplyFilter={onApplyFilter} onResetFilter={onResetFilter}>
              <SellerFilter filterValues={filterSellers} setFilterValues={setFilterSellers} />
            </FilterBar>
          </BarPanel>
        </>
      )}
      {isLoadingFileExport && <LineLoading />}
    </div>
  );
};
