import { useEffect, useState } from 'react';
import { Calendar } from '../../components/calendar/calendar';
import { StatCard, ColorStatCard, TypeStatCard } from '../../components/stat-card/stat-card';
import { bemCN } from '../../configs/bem-classname';
import { lastMonthRange, yeasteday } from '../../consts/calendar';
import { localStorageService, LocalStorageVariableName } from '../../services/localStorage-service';
import DinamicLineChart from '../../ui/dinamic-line-chart/dinamic-line-chart';
import { Expander } from '../../ui/expander/expander';
import { CalendarPeriod } from '../../view-models/calendar-period';
import { LineChartPropName } from '../../view-models/product-analitics-line-chart-item';
import { TableColumn } from '../../view-models/table-column';
import './subcategory-page.scss';
import {
  defaultCategoryFilterDto,
  defaultCategoryStatFilterDto,
  defaultProductsFilterForSubcategory,
  defaultSellerSubcategoryFilter,
} from '../../consts/default-filter-values';
import {
  CatalogFilterDto,
  CatalogStatisticsByDate,
  CatalogStocksDto,
  CategoryStatisticsDto,
  ProductDto,
  ProductFilterDateDto,
  SellerRowDto,
  SubCatalogsDto,
} from '../../api/Api';
import { sellersService } from '../../services/sellers-service';
import { useTable } from '../../hooks/useTable';
import { Pagination } from '../../components/pagination/pagination';
import { Table } from '../../ui/table/table';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as ArrowBackIcon } from '../../shared/image/svg/arrow-back.svg';
import { productsService } from '../../services/products-service';
import BigLoader from '../../components/big-loader/big-loader';
import { categoriesService } from '../../services/categories-service';
import { SmallLoader } from '../../components/small-loader/small-loader';
import { Checkbox } from '../../ui/checkbox/checkbox';
import { TableLoad } from '../../components/table-load/table-load';
import { subcategoryColumns } from '../../consts/subcategory-columns';
import { SellerFilterDto } from '../../view-models/seller-filter-dto';

const subcategoryPageCn = bemCN('subcategory-page');

export const SubcategoryPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [isSubcategoryLoading, setIsSubcategoryLoading] = useState(true);
  const [isSellerLoading, setIsSellerLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [themeIds, setThemeIds] = useState<number[]>(
    (searchParams.get('themeCategories')?.split(',') as string[])?.map((it: string) =>
      Number(it),
    ) || [],
  );
  const subCategoeyIds =
    (searchParams.get('subCategoriesIds')?.split(',') as string[])?.map((it: string) =>
      Number(it),
    ) || [];

  const navigate = useNavigate();
  const [category, setCategory] = useState<CategoryStatisticsDto>();
  const [stats, setStats] = useState<CatalogStatisticsByDate>();
  const [filter, setFilter] = useState<ProductFilterDateDto>({
    ...defaultCategoryStatFilterDto,
    themeCategories: themeIds,
    subCategoriesIds: subCategoeyIds,
  });
  const [sellersFilter, setSellersFilter] = useState<SellerFilterDto>({
    ...defaultSellerSubcategoryFilter,
    themeCategories: themeIds,
  });
  const [backCount, setBackCount] = useState(0);
  const [products, setProducts] = useState<ProductDto[]>([]);
  const [countProduct, setCountProduct] = useState<number>();
  const [subcategories, setSubcategories] = useState<SubCatalogsDto[]>([]);
  const [sellers, setSellers] = useState<SellerRowDto[]>([]);
  const [countSellers, setCountSellers] = useState<number>();

  const [dinamicData, setDinamicData] = useState<CatalogStocksDto[]>();
  const [tableProductColumns, setTableProductColumns] = useState<TableColumn[]>(
    localStorageService.getValue<TableColumn[]>(LocalStorageVariableName.productColumns) || [],
  );

  const [tableSubcategoryColumns, setTableSubcategoryColumns] = useState<TableColumn[]>(
    localStorageService.getValue<TableColumn[]>(LocalStorageVariableName.subcategoryColumns) ||
      subcategoryColumns,
  );

  const [tableSellersColumns, setTableSellersColumns] = useState<TableColumn[]>(
    localStorageService.getValue<TableColumn[]>(LocalStorageVariableName.sellerColumn) || [],
  );

  const [isAccessLoadSubcategories, setIsAccessLoadSubcategories] = useState(false);
  const [isAccessLoadSellers, setIsAccessLoadSellers] = useState(false);

  //Первоначальное получение данных о категории
  useEffect(() => {
    getCategory({
      ...defaultCategoryFilterDto,
      themeCategories: themeIds || undefined,
      subCategoriesIds: subCategoeyIds || undefined,
    });
  }, []);

  //Первоначальное получение товаров и статистики
  useEffect(() => {
    tableProductColumns.length == 0 &&
      productsService.getColumns().then((res: TableColumn[]) => setTableProductColumns(res));
    getProducts(filter);
    // getStats({ ...filter });
  }, []);

  //Получение данныех категории
  const getCategory = async (filter: ProductFilterDateDto) => {
    categoriesService.getSubcategory(filter).then((res: CategoryStatisticsDto) => {
      setCategory(res);
      setIsLoading(false);
    });
  };

  //Получение статистики по календарю
  const getStats = async (filter: ProductFilterDateDto) => {
    setStats(undefined);
    categoriesService.getSubcategoryStats(filter).then((res: CatalogStatisticsByDate) => {
      setStats(res);
    });
  };

  //Получение товаров
  const getProducts = async (filter: ProductFilterDateDto) => {
    setProducts([]);
    setIsProductLoading(true);
    // productsService.getProductCount({ ...filter }).then((res: number) => setCountProduct(res));
    productsService.getProductsByDate({ ...filter }).then((res: any) => {
      setProducts(res);
      setIsProductLoading(false);
    });
    setBackCount(backCount - 1);
  };

  //Получение количества товаров (для пагинации)
  const getCountProducts = async (filter: ProductFilterDateDto) => {
    productsService.getProductCount(filter).then((count:number) =>{
      console.log(count);
      setCountProduct(count);
    })
    // if (filter.withSales === true) {
    //   setCountProduct(category?.countArticulWithSales || 0);
    // } else {
    //   setCountProduct(category?.countArticuls || 0);
    //   // productsService.getProductCount(filter).then((res: number) => setCountProduct(res));
    // }
  };

  //Получение подкатегорий
  const getSubcategory = (filter: ProductFilterDateDto) => {
    setIsSubcategoryLoading(true);
    categoriesService.getSubcategoryTable(filter).then((res: SubCatalogsDto[]) => {
      setSubcategories(res);
      setIsSubcategoryLoading(false);
    });
  };

  const getSellers = (sellerFilter: SellerFilterDto) => {
    tableSellersColumns.length == 0 &&
      sellersService.getColumns().then((res: TableColumn[]) => setTableSellersColumns(res));

    setIsSellerLoading(true);
    sellerFilter.themeCategories &&
      categoriesService.getSellersSubcategory(sellerFilter).then((res: SellerRowDto[]) => {
        setSellers(res);
        setIsSellerLoading(false);
      });
  };

  //Получение количества продавцов (для пагинации)
  const getCountSellers = async () => {
    setCountSellers(category?.countSuppliers || 0);
  };

  //Получение графика
  const getDinamicData = (filter: ProductFilterDateDto) => {
    setDinamicData(undefined);
    categoriesService.getSubcategoryDinamicStats(filter).then((res: CatalogStocksDto[]) => {
      setDinamicData(res);
    });
  };

  useEffect(() => {
    getCountProducts({ ...filter });
    getStats({
      ...filter,
      themeCategories: themeIds || [],
      subCategoriesIds: subCategoeyIds || [],
      dateFrom: filter.dateFrom,
      dateTo: filter.dateTo,
      withSales: filter.withSales,
      onlyInStock: filter.onlyInStock,
    });
    getDinamicData({
      ...filter,
      themeCategories: themeIds || [],
      subCategoriesIds: subCategoeyIds || [],
      dateFrom: filter.dateFrom,
      dateTo: filter.dateTo,
      withSales: filter.withSales,
      onlyInStock: filter.onlyInStock,
    });
    isAccessLoadSubcategories &&
      getSubcategory({
        ...filter,
        themeCategories: themeIds || [],
        subCategoriesIds: subCategoeyIds || [],
        dateFrom: filter.dateFrom,
        dateTo: filter.dateTo,
        withSales: filter.withSales,
        onlyInStock: filter.onlyInStock,
      });
    setBackCount(backCount - 1);
  }, [filter.dateFrom, filter.dateTo, filter.withSales, filter.onlyInStock]);

  useEffect(() => {
    isAccessLoadSellers &&
      getSellers({
        ...filter,
        themeCategories: themeIds || [],
        dateFrom: filter.dateFrom,
        dateTo: filter.dateTo,
      });
    setBackCount(backCount - 1);
  }, [sellersFilter.dateFrom, sellersFilter.dateTo, isAccessLoadSellers]);

  useEffect(() => {
    isAccessLoadSubcategories &&
      getSubcategory({
        ...filter,
        themeCategories: themeIds || [],
        subCategoriesIds: subCategoeyIds || [],
        dateFrom: filter.dateFrom,
        dateTo: filter.dateTo,
        withSales: filter.withSales,
      });
  }, [isAccessLoadSubcategories]);

  useEffect(() => {
    getCountProducts({ ...filter });
    getCountSellers();
  }, [category]);

  //Подключение инструментов для работы с таблицей
  const {
    onPagination: onProductPagination,
    onSort: onProductSort,
    onChangeDate,
    pageNumber: productPageNumber,
  } = useTable(countProduct || 0, filter, defaultCategoryFilterDto, setFilter, getProducts, 10);

  const {
    onPagination: onSellerPagination,
    onSort: onSellerSort,
    onChangeDate: onSellerChangeDate,
    pageNumber: sellerPageNumber,
  } = useTable(
    countSellers || 0,
    sellersFilter,
    defaultSellerSubcategoryFilter,
    setSellersFilter,
    getSellers,
    10,
  );

  if (isLoading) return <BigLoader color="dark" />;
  return (
    <div className={subcategoryPageCn()}>
      <div className={subcategoryPageCn('header')}>
        <Button
          onClick={() => navigate(backCount)}
          color={ColorsButton.transition}
          size={SizesButton.small}
          icon={<ArrowBackIcon />}
        />
        <p data-title={category?.nameCategory} className={subcategoryPageCn('name', ['h1'])}>
          {category?.nameCategory}
        </p>
      </div>

      <div className={subcategoryPageCn('stats-cards')}>
        <div className={subcategoryPageCn('stat-item')}>
          <p>
            {category ? category.countSuppliers?.toLocaleString('ru-RU') + ' шт' : <SmallLoader />}
          </p>
          <p>Количество продавцов</p>
        </div>
        <div className={subcategoryPageCn('stat-item')}>
          <p>
            {category ? category.countBrends?.toLocaleString('ru-RU') + ' шт' : <SmallLoader />}
          </p>
          <p>Количество брендов</p>
        </div>
        <div className={subcategoryPageCn('stat-item')}>
          <p>
            {category ? category.countArticuls?.toLocaleString('ru-RU') + ' шт' : <SmallLoader />}
          </p>
          <p>Количество артикулов</p>
        </div>
        {/* <div className={subcategoryPageCn('stat-item')}>
          <p>1 209</p>
          <p>Монопольность</p>
        </div> */}
        <div className={subcategoryPageCn('stat-item')}>
          <p>
            {category ? (
              category.countSuppliersWithSale?.toLocaleString('ru-RU') + ' шт'
            ) : (
              <SmallLoader />
            )}
          </p>
          <p>Количество продавцов с продажами</p>
        </div>
        <div className={subcategoryPageCn('stat-item')}>
          <p>
            {category ? (
              category.countBrendsWithSale?.toLocaleString('ru-RU') + ' шт'
            ) : (
              <SmallLoader />
            )}
          </p>
          <p>Количество брендов с продажами</p>
        </div>
        <div className={subcategoryPageCn('stat-item')}>
          <p>
            {category ? (
              category.countArticulWithSales?.toLocaleString('ru-RU') + ' шт'
            ) : (
              <SmallLoader />
            )}
          </p>
          <p>Артикулов с продажами</p>
        </div>
        {/* <div className={subcategoryPageCn('stat-item')}>
          <p>
            {topStats ? topStats.buyoutPercent?.toLocaleString('ru-RU') + '%' : <SmallLoader />}
          </p>
          <p>Процент товаров с продажами</p>
        </div> */}
      </div>
      <div className={subcategoryPageCn('title')}>
        <p className="h2">Итоги за выбранный период</p>
        <Calendar
          value={new CalendarPeriod(lastMonthRange, yeasteday)}
          maxDate={yeasteday}
          changeValue={(v: CalendarPeriod) => {
            onChangeDate(v);
            onSellerChangeDate(v);
          }}
        />
      </div>
      <div className={subcategoryPageCn('charts')}>
        <StatCard
          title="Упущенная выручка"
          value={stats?.lostRevenue || -1}
          color={ColorStatCard.Blue}
          typeStatCard={TypeStatCard.grey}
          isLoad={stats ? false : true}
        />
        <StatCard
          title="Выручка"
          value={stats?.revenue || -1}
          color={ColorStatCard.Green}
          typeStatCard={TypeStatCard.grey}
          isLoad={stats ? false : true}
        />
        <StatCard
          title="Ср. продажи в день"
          value={stats?.averageRevenue || -1}
          color={ColorStatCard.Orange}
          typeStatCard={TypeStatCard.grey}
          isLoad={stats ? false : true}
        />
        <StatCard
          title="Процент выкупа"
          value={stats?.buyoutPercent || -1}
          color={ColorStatCard.Red}
          typeStatCard={TypeStatCard.grey}
          isLoad={stats ? false : true}
        />
        <StatCard
          title="Средняя цена"
          value={stats?.averagePrice || -1}
          color={ColorStatCard.Violet}
          typeStatCard={TypeStatCard.grey}
          isLoad={stats ? false : true}
        />
      </div>
      <Expander title={'Товары'}>
        <div>
          <div className={subcategoryPageCn('products-pagination')}>
            <div>
              <div className={subcategoryPageCn('checkbox')}>
                <Checkbox
                  isCheck={filter.withSales || false}
                  onClick={() => {
                    let newValue = filter.withSales === true ? false : true;
                    setFilter({ ...filter, withSales: newValue });
                  }}
                />
                <span>Только товары с продажами</span>
              </div>
              <div className={subcategoryPageCn('checkbox')}>
                <Checkbox
                  isCheck={filter.onlyInStock || false}
                  onClick={() => {
                    let newValue = filter.onlyInStock === true ? false : true;
                    setFilter({ ...filter, onlyInStock: newValue });
                  }}
                />
                <span>Только товары в наличии</span>
              </div>
            </div>

            <Pagination
              currentPageNumber={productPageNumber}
              totalPagesCount={countProduct ? Math.ceil(countProduct / 10) : 1}
              onClick={onProductPagination}
            />
          </div>
          {isProductLoading ? (
            <TableLoad />
          ) : (
            <>
              <Table
                localStorageColumns={LocalStorageVariableName.productColumns}
                columns={tableProductColumns}
                data={products}
                onSort={onProductSort}
                idActiveSort={filter.sortFieldId || 666}
                decs={filter.desc || false}
                // countRows={10}
              />
            </>
          )}
        </div>
      </Expander>
      <Expander
        title={'Подкатегории'}
        defaultActive={false}
        onOpen={() => setIsAccessLoadSubcategories(true)}
        onClose={() => setIsAccessLoadSubcategories(false)}
      >
        {isSubcategoryLoading ? (
          <TableLoad />
        ) : (
          <Table
            localStorageColumns={LocalStorageVariableName.subcategoryColumns}
            columns={tableSubcategoryColumns}
            data={subcategories}
            onSort={(idCol: number) => {}}
            idActiveSort={-1}
            decs={false}
          />
        )}
      </Expander>
      <Expander
        title={'Продавцы'}
        defaultActive={false}
        onOpen={() => setIsAccessLoadSellers(true)}
        onClose={() => setIsAccessLoadSellers(false)}
      >
        {isSellerLoading ? (
          <TableLoad />
        ) : (
          <>
            <Pagination
              currentPageNumber={sellerPageNumber}
              totalPagesCount={countSellers ? Math.ceil(countSellers / 10) : 1}
              onClick={onSellerPagination}
            />
            <Table
              localStorageColumns={LocalStorageVariableName.sellerColumn}
              columns={tableSellersColumns}
              data={sellers}
              onSort={onSellerSort}
              idActiveSort={sellersFilter.sortFieldId || 108}
              decs={sellersFilter.desc || false}
            />
          </>
        )}
      </Expander>
      <div className={subcategoryPageCn('line-chart')}>
        {dinamicData ? (
          <DinamicLineChart
            title="Анализ трендов"
            propNames={[
              new LineChartPropName(1, 'Продавцы', 'sellerCount', 'шт'),
              new LineChartPropName(2, 'Товары', 'productsCount', 'шт'),
              // new LineChartPropName(3, 'Остатки', 'stocks', 'шт'),
              new LineChartPropName(4, 'Цены', 'price', 'руб'),
              new LineChartPropName(5, 'Выручка', 'revenue', 'руб'),
              new LineChartPropName(6, 'Продажи', 'sales', 'шт'),
            ]}
            basePropName="date"
            data={dinamicData}
            basePropLabel={'Дата'}
          />
        ) : (
          <SmallLoader />
        )}
      </div>
    </div>
  );
};
