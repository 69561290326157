//import { AxiosHeaders } from 'axios';
import { QuickAuthRequestDto, SiteJobTitle, UserLoginDto, UserRegistrationDto } from '../api/Api';
import { API } from '../configs/api';
import { linksUrl } from '../consts/linksUrl';
import { localStorageService, LocalStorageVariableName } from './localStorage-service';

class AuthService {
  async auth(loginDto: UserLoginDto) {
    try {
      let res = API.auth
        .loginCreate(loginDto)
        .catch((error: any) => {
          if (error.status === 401) {
            // return 'Неккоректный логин или пароль!';
            return error.error.error;
          } else {
            // console.log(error);
            return 'Не удалось выполнить вход!';
          }
        })
        .then((res: any) => {
          if (res.status == '401') {
            return 'Неккоректный логин или пароль!';
          } else if (res.ok) {
            let token = res.data.accessToken;
            localStorageService.setValue(LocalStorageVariableName.isAuth, token);
            return res;
          } else {
            // console.log(res);
            return res;
            // return 'Не удалось выполнить вход!';
          }
        });
      return res;
    } catch (err: any) {
      return 'Не удалось выполнить вход!';
    }
  }

  getIsAuth() {
    let isAuth = localStorageService.getValue(LocalStorageVariableName.isAuth);
    if (isAuth && isAuth == 'true') {
      return true;
    } else {
      return false;
    }
  }

  async exit() {
    API.auth.logoutList().then((res: any) => {
      localStorageService.deleteValue(LocalStorageVariableName.isAuth);
      window.location.replace('/');
      return 200;
    });
  }

  async getRoles() {
    try {
      let res = API.auth.getListTitlesCreate();
      let result = (await res).data;
      return result as SiteJobTitle[];
    } catch (error: any) {
      console.log(error);
      return [];
    }
  }

  async registration(newUser: UserRegistrationDto) {
    try {
      let res = API.auth.registrationCreate(newUser);
      let result = (await res).data;
    } catch (error: any) {
      console.log(error);
      if (error.status == 422) {
        return 'Данный адрес электронной почты уже зарегистирован в системе.';
      }
    }
  }

  async confirmationEmail(key: string) {
    try {
      let res = API.auth.confirmEmailList({ id: key });
      let result = (await res).data;
    } catch (error: any) {
      console.log(error);
    }
  }

  async recoveryPasswordRequest(email: string) {
    try {
      let res = API.auth.recoverPasswordCreate({ email });
      let result = (await res).data;
    } catch (error: any) {
      console.log(error);
    }
  }

  async updatePassword(key: string, password: string) {
    try {
      let res = API.auth.updatePasswordCreate({ key, password });
      let result = (await res).data;
      return 200;
    } catch (error: any) {
      console.log(error);
      return 500;
    }
  }

  async quickAuth(request: QuickAuthRequestDto) {
    try {
      let res:any = API.auth.quickAuthCreate(request).then((res:any) =>{
        // console.log(res);
        localStorageService.setValue(LocalStorageVariableName.isAuth, "true");
        return res;
      })
       return res;
    } catch (error: any) {
      console.log(error);
      return 500;
    }
  }
}

export const authService: AuthService = new AuthService();
