import { useEffect, useState } from 'react';
import { bemCN } from '../../configs/bem-classname';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import './profile-tariffs-page.scss';
import BigLoader from '../../components/big-loader/big-loader';
import { TableColumn } from '../../view-models/table-column';
import { paymentHistoryColumns } from '../../consts/payment-history-columns';
import { CurrentSubscriptionDto, OrderHistoryDto, SellableItem } from '../../api/Api';
import { Table } from '../../ui/table/table';
import { LocalStorageVariableName } from '../../services/localStorage-service';
import { paymentService } from '../../services/payment-service';
import { PaymentItem } from '../../view-models/payment-item';
import { formatFullDate } from '../../utils/date';
import { ColorsModal, Modal } from '../../ui/modal/modal';
import { Select, TypeSelectChild } from '../../components/select/select';
import { RadioGroup } from '../../components/radio-group/radio-group';
import { SelectItem } from '../../view-models/select-item';
import { ReactComponent as CheckMarkIcon } from '../../shared/image/svg/check-mark.svg';
import { SimpleTable } from '../../ui/simple-table/simple-table';
import { sendMetrics, MerticNames } from '../../utils/metrics';

const profileTariffsCN = bemCN('profile-tariffs-page');
export const ProfileTariffsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tableColumns, setTableColumns] = useState<TableColumn[]>(paymentHistoryColumns);
  const [historyItems, setHistoryItems] = useState<PaymentItem[]>([]);
  const [currentTariff, setCurrentTariff] = useState<CurrentSubscriptionDto>();
  const isActiveTariff = paymentService.getIsActiveTariff();
  const [isShowModal, setIsShowModal] = useState(false);
  const [tarrifs, setTariffs] = useState<SellableItem[]>([]);
  const [checkTarrif, setCheckTariff] = useState<SellableItem>();
  useEffect(() => {
    getHistoryItem();
    getCurrentTariff();
  }, []);

  useEffect(() => {
    paymentService.getTariffs().then((res: SellableItem[]) => {
      setTariffs(res);
      setCheckTariff(res[1]);
    });
  }, []);

  const getHistoryItem = () => {
    paymentService.getHistory().then((res: PaymentItem[]) => {
      setIsLoading(true);
      setHistoryItems(res);
      setIsLoading(false);
    });
  };

  const getCurrentTariff = () => {
    paymentService.getCurrentTariff().then((res: CurrentSubscriptionDto) => setCurrentTariff(res));
  };

  const clickPaymentButton = () => {
    sendMetrics('reachGoal', MerticNames.clickPaymentButtonClick);
    paymentService
      .initPayment(checkTarrif?.id || -1)
      .then((res: string) => window.open(res, '_blank'));
  };

  if (isLoading) return <BigLoader color="dark" />;
  return (
    <div className={profileTariffsCN()}>
      <p className="h1">Текущий тариф</p>
      <div className={profileTariffsCN('current-tariff')}>
        <div>
          <p className={profileTariffsCN('title')}>Тариф:</p>
          <p className={profileTariffsCN('value')}>{currentTariff?.name}</p>
          <p className={profileTariffsCN('title')}>Дата окончания тарифа:</p>
          <p className={profileTariffsCN('value', { isActive: isActiveTariff ? 'green' : 'red' })}>
            {formatFullDate(currentTariff?.expiryDate || '')}
          </p>
        </div>
        <Button
          onClick={() => {
            sendMetrics('reachGoal', MerticNames.changeTariffButtonClick);
            setIsShowModal(true);
          }}
          size={SizesButton.big}
          color={ColorsButton.violet}
          text="Сменить тариф"
        />
      </div>
      <p className="h1">История платежей</p>
      {/* <Table
        localStorageColumns={LocalStorageVariableName.paymentColumn}
        columns={tableColumns}
        data={historyItems}
        onSort={(id: number) => {}}
        idActiveSort={-1}
        decs={false}
        isSimpleTable
        countRows={10}
      /> */}
      <SimpleTable columns={tableColumns} data={historyItems} />

      <Modal
        title=""
        isShowDialog={isShowModal}
        setIsShowDialog={setIsShowModal}
        color={ColorsModal.light}
      >
        <div className={profileTariffsCN('check-tariff')}>
          <Select
            typeSelectChild={TypeSelectChild.radio}
            activeNames={checkTarrif ? [checkTarrif.name || ''] : []}
          >
            <RadioGroup
              items={tarrifs
                .filter((tr: SellableItem) => tr.basePrice !== 0)
                .map(
                  (t: SellableItem, index: number) => new SelectItem(t.id || index, t.name || ''),
                )}
              activeId={checkTarrif?.id || -1}
              onChange={(id: number) => {
                setCheckTariff(tarrifs.find((t: SellableItem) => t.id === id));
              }}
            />
          </Select>
          <p>
            {checkTarrif && checkTarrif.basePrice && checkTarrif.basePrice !== 0
              ? (checkTarrif.basePrice / 100).toLocaleString('ru-RU') + ' ₽'
              : 'Бесплатно'}
          </p>
          <Button
            onClick={clickPaymentButton}
            size={SizesButton.big}
            color={ColorsButton.green}
            text="Оплатить"
          />
          <div className={profileTariffsCN('tariff-opt')}>
            {checkTarrif &&
              checkTarrif.description?.map((d: string) => (
                <div>
                  <CheckMarkIcon />
                  <p>{d}</p>
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};
