import { DirectionPaginationClick } from '../components/pagination/pagination';
import { useEffect, useState } from 'react';
import { CalendarPeriod } from '../view-models/calendar-period';
import { toCalendarDate } from '../utils/date';
import { useSearchParams } from 'react-router-dom';

export function useTable(
  countObjects: number,
  filterValue: any,
  defaultFilterValue: any,
  setFilterValue: (v: any) => void,
  getObjects: (filter: any) => void,
  countRows?: number,
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [countRener, setCountRender] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  //Обработка клика на пагинацию
  const onPagination = (directionPaginationClick: DirectionPaginationClick) => {
    if (
      directionPaginationClick == DirectionPaginationClick.next &&
      countObjects &&
      pageNumber < Math.ceil(countObjects / (countRows || 50))
    ) {
      setFilterValue({
        ...filterValue,
        offset: filterValue.offset ? Number(filterValue.offset) + 1 : 1,
      });
      setPageNumber(pageNumber + 1);
    } else if (directionPaginationClick == DirectionPaginationClick.back && pageNumber > 1) {
      setFilterValue({
        ...filterValue,
        offset: filterValue.offset ? Number(filterValue.offset) - 1 : 0,
      });
      setPageNumber(pageNumber - 1);
    }
  };

  //Обработка клика на сортровку
  const onSort = (idColumn: number) => {
    let newDesc = filterValue.sortFieldId == idColumn ? !filterValue.desc : true;
    setFilterValue({ ...filterValue, sortFieldId: idColumn, desc: newDesc });
  };

  //Получение фильтров из адресной строки
  const getParams = () => {
    let newFilter: any = { ...defaultFilterValue };
    searchParams.forEach((value: any, key: string) => {
      if (
        value == '' ||
        (value as []).length === 0 ||
        value == undefined ||
        value.includes('undefined')
      ) {
      } else if (key === 'subCategoriesIds') {
        newFilter[key] = value.split(',').map((item: string) => Number(item));
      } else if (key === 'brandIds') {
        newFilter[key] = value.split(',').map((item: string) => Number(item));
      } else if (key === 'countryIds') {
        newFilter[key] = value.split(',').map((item: string) => Number(item));
      } else if (key == 'categoriesIds') {
        newFilter[key] = value.split(',').map((item: string) => Number(item));
      }else if (key == 'categoryIds') {
        newFilter[key] = value.split(',').map((item: string) => Number(item));
      }
       else if (key === 'themeCategories') {
        newFilter[key] = value ? value.split(',').map((item: string) => Number(item)) : [];
      } else if (key === 'typeIds') {
        newFilter[key] = value ? value.split(',').map((item: string) => Number(item)) : [];
      } else if (key === 'statusIds') {
        newFilter[key] = value ? value.split(',').map((item: string) => Number(item)) : [];
      } else if (key === 'offset') {
        newFilter[key] = parseInt(value) || 0;
      } else if (key.toLowerCase().includes('price')) {
        newFilter[key] = Number(value) || 0;
      } else if (key === 'desc') {
        newFilter[key] = Boolean(JSON.parse(value));
      } else if (key === 'active') {
        newFilter[key] = Boolean(JSON.parse(value));
      } else if (key === 'withSales') {
        newFilter[key] = Boolean(JSON.parse(value));
      } else if (key === 'onlyInStock') {
        newFilter[key] = Boolean(JSON.parse(value));
      } else if (key === 'name') {
        newFilter[key] = value.toString();
      } else if (key === 'inn') {
        newFilter[key] = value.toString();
      } else if (key === 'sortFieldId') {
        newFilter[key] = Number(value) || 0;
      } else if (key === 'countSaleFrom') {
        newFilter[key] = Number(value) || 0;
      } else {
        newFilter[key] = Number(value) || value;
      }
    });
    setFilterValue(newFilter);
    return newFilter;
  };

  //Сохранение фильтров в адресную строку
  const saveParams = (filter: any) => {
    let newParams: URLSearchParams = new URLSearchParams();
    for (const key in filter) {
      if (
        filter[key] === undefined ||
        filter[key] === '' ||
        (filter[key] as []).length === 0 ||
        filter[key] === null
      ) {
      } else {
        if (key == 'offset') {
          setPageNumber((filter as any)[key] + 1);
        }
        newParams.set(key, (filter as any)[key]);
      }
    }
    setSearchParams(newParams);
  };

  //Изменение даты в календаре
  const onChangeDate = (newDate: CalendarPeriod) => {
    let newFilter: any = {
      ...filterValue,
      dateFrom: toCalendarDate(newDate.dateFrom),
      dateTo: toCalendarDate(newDate.dateTo),
    };
    setFilterValue(newFilter);
  };

  //Обработчик событий
  useEffect(() => {
    // saveParams(filterValue);

    if (countRener > 0) {
      saveParams(filterValue);
      getObjects(filterValue);
    }

    setCountRender(countRener + 1);
  }, [
    filterValue.offset,
    filterValue.desc,
    filterValue.sortFieldId,
    filterValue.dateFrom,
    filterValue.dateTo,
    filterValue.withSales,
    filterValue.onlyInStock,
    filterValue.cabinetId,
  ]);

  return { onPagination, onSort, getParams, saveParams, onChangeDate, pageNumber, setPageNumber };
}
