export const sendMetrics = (type: string, id: MerticNames) => {
  (window as any).ym(97507982, type, id);
};

export const enum MerticNames {
  registrationButtonClick = 'registrationButtonClick',
  checkFreeButtonClick = 'checkFreeButtonClick',
  changeTariffButtonClick = 'changeTariffButtonClick',
  checkColumnsButtonClick = 'checkColumnsButtonClick',
  clickPaymentButtonClick = 'clickPaymentButtonClick',
  clickStartButtonClick = 'clickStartButtonClick',
  sendFeedbackForm = 'sendFeedbackForm',
  sendRegForm = 'sendRegForm',
}
