import './sidebar-item.scss';
import { bemCN } from '../../../configs/bem-classname';
import { FC, ReactNode } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowTop } from '../../../shared/image/svg/arrow-top.svg';
import { ReactComponent as ArrowBottom } from '../../../shared/image/svg/arrow-bottom.svg';
import { authService } from '../../../services/auth-service';
import { linksUrl } from '../../../consts/linksUrl';
import path from 'path';
import { dictionaryService } from '../../../services/dictionary-service';

type link = {
  name: string;
  path: string;
};

interface SideBarItemProps {
  id: number;
  title: string;
  icon: ReactNode;
  path?: string;
  links?: Array<link>;
  active: boolean;
  onHideMenuModile: () => void;

  setActive(val: number | null): void;
}
const SideBarItem: FC<SideBarItemProps> = ({
  id,
  title,
  icon,
  links,
  active,
  path,
  setActive,
  onHideMenuModile,
}) => {
  const sidebarItemCn = bemCN('sidebarItem');
  const navigate = useNavigate();

  return (
    <div
      className={sidebarItemCn()}
      onClick={() => {
        setActive(!active ? id : null);
        if (title === 'Выход') {
          authService.exit().then((res: any) => {
            // console.log(res);
          });
        }
        if(title==="CRM"){
          dictionaryService.getCRMLink().then((l: string) => {
             console.log(l);
            window && window.open(l, '_blank')?.focus();
          });
        }
      }}
    >
      <div>
        <div
          onClick={
            !links && path
              ? () => {
                  onHideMenuModile();
                  navigate(path);
                }
              : () => links && navigate(links[0].path)
          }
        >
          {icon}
        </div>
        <div
          onClick={
            !links && path
              ? () => {
                  onHideMenuModile();
                  navigate(path);
                }
              : () => {}
          }
          className={sidebarItemCn('title', ['h4'])}
        >
          {title}
        </div>
        {!!links?.length && (
          <div className={sidebarItemCn('arrow')}>{active ? <ArrowBottom /> : <ArrowTop />}</div>
        )}
      </div>

      {active && (
        <div className={sidebarItemCn('subcategories')}>
          {links?.map((elem) => {
            return (
              <div
                onClick={() => {
                  onHideMenuModile();
                  navigate(elem.path + '/');
                }}
                className="h5"
                key={elem.name}
                // to={elem?.path + '/'}
              >
                {elem.name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SideBarItem;
