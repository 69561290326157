import { Outlet } from 'react-router-dom';
import { TopMenu } from '../../components/top-menu/top-menu';
import { bemCN } from '../../configs/bem-classname';
import './analytics-page.scss';
import { topMenuLinksAnalytics } from '../../consts/top-menu-links';
const analyticsPageCn = bemCN("analytics-page");

export const AnalyticsPage = () => {
  return (
    <div className={analyticsPageCn()}>
      <TopMenu links={topMenuLinksAnalytics}/>
      <Outlet />
    </div>
  );
};
