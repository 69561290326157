import { bemCN } from '../../configs/bem-classname';
import './quick-login.scss';
import { useGoogleLogin } from '@react-oauth/google';
import * as VKID from '@vkid/sdk';
import { generateCodeChallengeFromVerifier, generateCodeVerifier } from '../../utils/code-generate';
import { authService } from '../../services/auth-service';
import { QuickAuthRequestDto, TypeQuickAuth } from '../../api/Api';
import { QuikAuthSetting } from '../../configs/quick-auth-setting';
import { useEffect, useState } from 'react';
import { Button, ColorsButton, SizesButton } from '../../ui/button/button';
import { ReactComponent as GoogleIcon } from '../../shared/image/svg/google-logo-auth.svg';
import { useNavigate } from 'react-router-dom';
import { linksUrl } from '../../consts/linksUrl';

const quickLoginCn = bemCN('quick-login');

export const QuickLogin = () => {
  // const codeVerifier = generateCodeVerifier();
  // const codeChannel =   await generateCodeChallengeFromVerifier(codeVerifier);
  const navigate = useNavigate();
  const onClickGoogleButton = useGoogleLogin({
    // onSuccess: tokenResponse => console.log(tokenResponse),
    flow: 'auth-code',

    onSuccess: (codeResponse: any) => googleSendCode(codeResponse),
    onError: (e: any) => console.log(e),
  });

  const [codeVerifier, setCodeVerifier] = useState(generateCodeVerifier());
  const [codeChannel, setCodeChannel] = useState('');

  useEffect(() => {
    generateCodeChallengeFromVerifier(codeVerifier).then((str: string) => {
      setCodeChannel(str);
    });
  }, [codeVerifier]);

  VKID.Config.init({
    app: QuikAuthSetting.vkAppId,
    redirectUrl: QuikAuthSetting.redirectUrl,
    responseMode: VKID.ConfigResponseMode.Callback,
    source: VKID.ConfigSource.LOWCODE,
    codeChallenge: codeChannel,
    // codeVerifier: codeVerifier,
    scope: 'email phone',
  });
  const oneTap = new VKID.OneTap();

  useEffect(() => {
    let vkDiv = document.getElementById('vkAuthBtn');
    if (vkDiv && vkDiv.childElementCount == 0) {
      oneTap.render({
        container: vkDiv,
        fastAuthEnabled: false,
        showAlternativeLogin: true,
        styles: {
          borderRadius: 5,
          width: 40,
          height: 40,
        },
      });

      oneTap.on(VKID.WidgetEvents.ERROR, () => console.log('Ошибка авторизации VK'));
      oneTap.on(VKID.OneTapInternalEvents.LOGIN_SUCCESS, (payload: any) => {
        const code = payload.code;
        const deviceId = payload.device_id;
        let quickAuthRequest: QuickAuthRequestDto = {};
        quickAuthRequest.token = code;
        quickAuthRequest.typeQuickAuth = TypeQuickAuth.Value1;
        quickAuthRequest.deviceId = deviceId;
        quickAuthRequest.codeVerifier = codeVerifier;

        authService.quickAuth(quickAuthRequest).then(() => {
          // console.log("Vk работает")
          navigate(linksUrl.analyitcs + linksUrl.analyticsProduct);
        });

        // VKID.Auth.exchangeCode(code, deviceId).then(vkidOnSuccess).catch(vkidOnError);
      });
    }
  }, [1]);

  const googleSendCode = (response: any) => {
    // console.log(response);
    let quickAuthRequest: QuickAuthRequestDto = {};
    quickAuthRequest.token = response.code;
    quickAuthRequest.typeQuickAuth = TypeQuickAuth.Value2;

    authService.quickAuth(quickAuthRequest).then(() => {
      navigate(linksUrl.analyitcs + linksUrl.analyticsProduct);

      // console.log("Vk работает")
    });
  };
  return (
    <div className={quickLoginCn()}>
      <Button
        onClick={onClickGoogleButton}
        size={SizesButton.small}
        color={ColorsButton.white}
        icon={<GoogleIcon />}
      />
      <div id="vkAuthBtn"></div>
    </div>
  );
};
