import {
  FilterFieldDto,
  FilterNavigationDto,
  ProductFilterDateDto,
  SellerDto,
  SellerFilterDownloadingDto,
  SellerFilterDto as SellerFilterDtoApi,
  SellerFilterStatisticsDto,
  SellerRowDto,
  SellerStatisticsCardDto,
  SellerStatisticsDto,
} from '../api/Api';
import { API } from '../configs/api';
import { linkColumnsName } from '../consts/link-column-names';
import { formatFullDate } from '../utils/date';
import { saveFile } from '../utils/saveFile';
import { SellerFilterDto } from '../view-models/seller-filter-dto';
import { TableColumn, TypeColumn } from '../view-models/table-column';
import { TypeFile } from '../view-models/type-files';

class SellersService {
  async getColumns() {
    try {
      let res = API.sellers.getFieldsSellerCreate();
      let result = (await res).data;
      let columns: TableColumn[] = result.map((f: FilterFieldDto) => {
        return new TableColumn(
          f.id || -1,
          f.siteFieldName || '',
          f.name || '',
          TypeColumn.string,
          f.defaultActive || false,
          undefined,
          f.isSortable,
          linkColumnsName.includes(f.siteFieldName || ''),
          f.sort
        );
      });
      return columns || [];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getSellers(filter: SellerFilterDto) {
    try {
      let apiFilter = this.getApiFilter(filter);
      let res = API.sellers.getSellersCreate({ ...apiFilter, sourceLink: window.location.href });
      let result = (await res).data;
      return result
              ? result.map((s: SellerRowDto) => {
                  return {
                    ...s,
                    dateUpdate: formatFullDate(s.dateUpdate || ''),
                    registrationDate: formatFullDate(s.registrationDate || ''),
                  };
                })
              : [];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  getApiFilter(filter: SellerFilterDto) {
    let apiFilter: SellerFilterDtoApi = {
      brandIds: filter.brandIds,
      catalogsIds: filter.catalogsIds,
      categoryIds: filter.categoryIds,
      feedbacksCountFrom: filter.feedbacksCountFrom,
      feedbacksCountTo: filter.feedbacksCountTo,
      inn: filter.inn,
      name: filter.name,
      org: filter.orgn,
      productName: filter.productName,
      ratingFrom: filter.ratingFrom,
      ratingTo: filter.ratingTo,
      registrationFrom: filter.registrationFrom,
      registrationTo: filter.registrationTo,
      revenueFrom: filter.revenueFrom,
      revenueTo: filter.registrationTo,
      period: filter.period,
      active: Boolean(filter.active),
      sourceLink: filter.sourceLink,
      navigation: {
        desc: filter.desc,
        limit: filter.limit,
        offset: filter.offset,
        sortFieldId: filter.sortFieldId,
      },
    } as SellerFilterDtoApi;
    return apiFilter;
  }

  async getSellersStat(filter: SellerFilterStatisticsDto) {
    try {
      let filterApi = this.getApiFilter(filter);
      let res = API.sellers.getStatisticsCreate(filterApi);
      let result = (await res).data;
      return result as SellerStatisticsDto[];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getSellerStat(filter: ProductFilterDateDto) {
    try {
      let res = API.sellerCard.getSellerStatisticsByFilterCreate(filter);
      let result = (await res).data;
      return result as SellerStatisticsCardDto[];
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }


  async getSellerCount(filter: SellerFilterDto) {
    try {
      let filterApi = this.getApiFilter(filter);
      let res = API.sellers.getCountSellersCreate(filterApi);
      let result = (await res).data;
      return result as number;
    } catch (err: any) {
      console.log(err);
      return -1;
    }
  }

  async getSeller(id:number) {
    try {
      let res = API.sellerCard.sellerCreate({id});
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return {};
    }
  }

  async startExportFile(filter: SellerFilterDto, typeFile: TypeFile, fieldsIds: number[]) {
    try {
      let fileFilter: SellerFilterDownloadingDto = {
        typeFile: Number(typeFile),
        fieldIds: fieldsIds,
        ...this.getApiFilter(filter),
      };
      let res = API.sellers.createDownloadFileCreate(fileFilter);
      let result = (await res).data;
      return result;
    } catch (err: any) {
      console.log(err);
      return -1;
    }
  }

  async getFile(idFile: string, fileType: TypeFile) {
    try {
      let res = API.sellers.getFileByIdList({ id: idFile });
      let result = await (await res).blob();
      saveFile(result, TypeFile[fileType]);
    } catch (err: any) {
      console.log(err);
      return '';
    }
  }

}

export const sellersService: SellersService = new SellersService();
