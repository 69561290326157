export const QuikAuthSetting: {
  vkAppId: number;
  googleAppId: string;
  redirectUrl: string;
} = {
  vkAppId: 52914722,
  googleAppId: '617872831741-kfebo1brjef5v9583gt0r3onj1breh3j.apps.googleusercontent.com',
  // redirectUrl: 'https://test.xstat.ru',
  redirectUrl: 'https://xstat.ru',
};
