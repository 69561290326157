import { FC, useEffect, useState } from 'react';
import './warehouse-table.scss';
import { bemCN } from '../../configs/bem-classname';
import { Stock, Warehouse, WarehouseStock } from '../../view-models/warehouse';
import { CheckGroup, CheckMarkPosition } from '../check-group/check-group';
import { SelectItem } from '../../view-models/select-item';
import { Button, SizesButton, ColorsButton } from '../../ui/button/button';
import { ReactComponent as QuestionIcon } from '../../shared/image/svg/question.svg';


interface WarehouseTableProps {
  id?: string;
  warehouses: Warehouse[];
  warehousesStock: Stock[];
}

const fields = [
  { id: 1, name: 'Остатки', propName: 'stoksCount' },
  { id: 2, name: 'Выручка', propName: 'renevue' },
  { id: 3, name: 'Продажи', propName: 'salesCount' },
];

const warehouseTableCn = bemCN('warehouse-table');

export const WarehouseTable: FC<WarehouseTableProps> = ({ id, warehouses, warehousesStock }) => {
  const [activeFieldsIds, setActiveFieldsIds] = useState<number[]>([1, 2, 3]);
  const [activeWarehouseIds, setActiveWareHouseIds] = useState<number[]>(
    warehouses.map((w: Warehouse) => w.id),
  );

  const onChangeFields = (ids: number[]) => {
    setActiveFieldsIds(ids);
  };

  const onClickWarehouse = (idWarehouse: number) => {
    activeWarehouseIds.includes(idWarehouse)
      ? setActiveWareHouseIds([...activeWarehouseIds.filter((i) => i !== idWarehouse)])
      : setActiveWareHouseIds([...activeWarehouseIds.concat(idWarehouse)]);
  };


  return (
    <div id={id} className={warehouseTableCn()}>
      <div className={warehouseTableCn('header')}>
        <div>
          <p className="h2">Остатки и продажи в день</p>
          <div
            className={warehouseTableCn('help')}
            data-title={
              'Данные о разбиении остатков и продаж по складам предоставлены с 01.05.2024'
            }
          >
            <Button
              icon={<QuestionIcon />}
              size={SizesButton.small}
              color={ColorsButton.transition}
            />
          </div>
        </div>
        <CheckGroup
          items={fields.map((f: any) => new SelectItem(f.id, f.name))}
          activeIds={activeFieldsIds}
          checkMarkPosition={CheckMarkPosition.left}
          onChange={onChangeFields}
        />
      </div>

      <div className={warehouseTableCn('check-warehouse')}>
        {warehouses.map((w: Warehouse) => (
          <p
            onClick={() => onClickWarehouse(w.id)}
            className={activeWarehouseIds.includes(w.id) ? '' : 'disable'}
            key={w.id}
          >
            {w.name}
          </p>
        ))}
      </div>

      <div className={warehouseTableCn('table')}>
        <div>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>Дата</th>
                {fields
                  .filter((f: any) => activeFieldsIds.includes(f.id))
                  .map((f: any) => (
                    <th key={f.name} className={warehouseTableCn('th-background')} rowSpan={2}>
                      {f.name} по всем складам
                    </th>
                  ))}
                {warehouses
                  .filter((w: Warehouse) => activeWarehouseIds.includes(w.id))
                  .map((wh: Warehouse, index: number) => (
                    <th
                      className={warehouseTableCn('double-h', {
                        isGreen: index % 2 ? true : false,
                      })}
                      key={wh.id}
                      rowSpan={1}
                      colSpan={activeFieldsIds.length}
                    >
                      {wh.name}
                    </th>
                  ))}
              </tr>
              <tr>
                {warehouses
                  .filter((w: Warehouse) => activeWarehouseIds.includes(w.id))
                  .map((wh: Warehouse, index: number) => {
                    return fields
                      .filter((f: any) => activeFieldsIds.includes(f.id))
                      .map((f: any) => (
                        <th
                          className={warehouseTableCn('double-h', {
                            isGreen: index % 2 ? true : false,
                          })}
                          key={f.id+wh.name}
                        >
                          {f.name}
                        </th>
                      ));
                  })}
              </tr>
            </thead>
            <tbody>
              {warehousesStock.map((ws: Stock) => (
                <tr key={ws.date}>
                  <td>{ws.date}</td>
                  {fields
                    .filter((f: any) => activeFieldsIds.includes(f.id))
                    .map((f: any) => (
                      <td className={warehouseTableCn('th-background')} 
                      key={ws.date + f.id}>
                        {(ws as any)[f.propName].toLocaleString('ru-RU')}
                      </td>
                    ))}
                  {ws.stocks
                    .filter((w: WarehouseStock) => activeWarehouseIds.includes(w.id))
                    .map((st: WarehouseStock, index: number) => {
                      return fields
                        .filter((f: any) => activeFieldsIds.includes(f.id))
                        .map((fd: any) => (
                          <td
                            className={index % 2 == 0 ? '' : warehouseTableCn('th-background')}
                            key={st.name + '_' + st.id + '_' + fd.id}
                          >
                            {(st as any)[fd.propName].toLocaleString('ru-RU')}
                          </td>
                        ));
                    })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
