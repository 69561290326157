import { FC, useEffect, useState } from 'react';
import { RangeInputs, TypeRangeValue } from '../../range-inputs/range-inputs';
import { bemCN } from '../../../configs/bem-classname';
import { CheckGroup, CheckMarkPosition } from '../../check-group/check-group';
import { SelectItem } from '../../../view-models/select-item';
import { Select, TypeSelectChild } from '../../select/select';
import './sellers-filter.scss';
import { Checkbox } from '../../../ui/checkbox/checkbox';
import { Input, TypeDataInput, TypeViewInput } from '../../../ui/input/input';
import { SellerFilterDto } from '../../../view-models/seller-filter-dto';
import { BrandDto, CatalogDto } from '../../../api/Api';
import { categoriesService } from '../../../services/categories-service';
import { brandService } from '../../../services/brands-service';
import { SearchInput } from '../../search-input/search-input';
import { FilterCategorySelect } from '../../category-selected-item/filter-category-select';
import { CategoryTree } from '../../../view-models/category';
import { defaultSellerStatFilter } from '../../../consts/default-filter-values';
import { SearchFilterCategorySelect } from '../../category-selected-item/search-filter-category-select';

export interface SellersFilterProps {
  filterValues: SellerFilterDto;
  setFilterValues: (newF: SellerFilterDto) => void;
}
const sellersFilterBarCn = bemCN('sellers-filter');
export const SellerFilter: FC<SellersFilterProps> = ({ filterValues, setFilterValues }) => {
  const [categories, setCategories] = useState<CategoryTree[]>();
  const [checkCategoryNames, setCheckCategoryNames] = useState<string[]>([]);
  const [filterCategories, setFilterCategories] = useState<CategoryTree[]>();
  const [searchValueCategory, setSearchValueCategory] = useState('');
  const [searchValueBrand, setSearchValueBrand] = useState('');
  const [brands, setBrands] = useState<BrandDto[]>([]);
  const [selectBrands, setSelectBrands] = useState<BrandDto[]>([]);

  //Поиск в брендах
  const onChangeSearchBrand = (v: string) => {
    setSearchValueBrand(v);
    getBrandsByName(v);
  };

  // useEffect(() => {
  //   // getBrandsByName('A');
  // }, [1]);

  useEffect(() => {
    categoriesService.getCategories().then((res: CategoryTree[]) => {
      setCategories(res);
    });
  }, [1]);

  const getBrandsByName = (v: string) => {
    // console.log('Поиск брендов');
    brandService.getBrands({ name: v, limit: 10 }).then((res: BrandDto[]) => {
      setBrands(res);
      setSelectBrands(res.filter((b: BrandDto) => filterValues.brandIds?.includes(b.id || -1)));
    });
  };

  //Поиск в категориях
  const onChangeSearchCategory = (v: string) => {
    // console.log('Поиск категорий');
    const extractVal = (items: CategoryTree[]): CategoryTree[] => {
      return items
        .map((item: CategoryTree) => {
          return { ...item, children: extractVal((item.children as CategoryTree[]) || []) };
        })
        .filter(
          (item: CategoryTree) =>
            (item.children && item.children.length > 0) ||
            item.label.toLowerCase().includes(v.toLowerCase()),
        );
    };
    let newFilterCategories: CategoryTree[] = extractVal(categories || []);
    setFilterCategories(newFilterCategories);
    setSearchValueCategory(v);
  };

  useEffect(() => {
    return () => {
      setSearchValueBrand('');
    };
  });

  useEffect(() => {
    if (JSON.stringify(filterValues) === JSON.stringify(defaultSellerStatFilter)) {
      setSearchValueCategory('');
      setCheckCategoryNames([]);

      setSelectBrands([]);
      getBrandsByName('A');
    }
  }, [filterValues]);
  //Получение активных категорий для подписи в селектор
  const getActiveLabels = (cats: CategoryTree[]) => {
    let result: string[] = [];
    cats.map((c: CategoryTree, index: number) => {
      if (c.children) {
        let adding = getActiveLabels(c.children || []);
        result = result.concat(adding);
      } else {
        filterValues.categoryIds &&
          filterValues.categoryIds.includes(Number(c.value)) &&
          result.push(c.label);
      }
    });
    return result;
  };

  useEffect(() => {
    setCheckCategoryNames(getActiveLabels(categories || []));
  }, [filterValues.categoryIds, categories]);

  const onChangeRangeValue = (typeRangeValue: TypeRangeValue, value: number, name: string) => {
    if (typeRangeValue === TypeRangeValue.from) {
      setFilterValues({ ...filterValues, [`${name}From`]: Number(value) });
    } else if (typeRangeValue === TypeRangeValue.to) {
      if (Number(value) === 0) setFilterValues({ ...filterValues, [`${name}To`]: undefined });
      else setFilterValues({ ...filterValues, [`${name}To`]: Number(value) });
    }
  };

  return (
    <div className={sellersFilterBarCn()}>
      <div className={sellersFilterBarCn('isActivePanel')}>
        <span>Продавец</span>
        <div>
          <Checkbox
            isCheck={filterValues.active ? filterValues.active : false}
            onClick={() => setFilterValues({ ...filterValues, active: !filterValues.active })}
          />
          <span>Активный</span>
        </div>
      </div>
      <Input
        typeData={TypeDataInput.text}
        typeView={TypeViewInput.normal}
        value={filterValues.name || ''}
        placeholder="Название поставщика"
        onChange={(v: string) => setFilterValues({ ...filterValues, name: v })}
      />
      <Input
        typeData={TypeDataInput.number}
        typeView={TypeViewInput.normal}
        value={filterValues.inn || ''}
        placeholder="ИНН"
        onChange={(v: string) => setFilterValues({ ...filterValues, inn: v })}
      />

      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'revenue');
        }}
        toValue={filterValues.revenueTo || undefined}
        fromValue={filterValues.revenueFrom || undefined}
        min={0}
        title="Выручка, ₽"
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'rating');
        }}
        toValue={filterValues.ratingTo || undefined}
        fromValue={filterValues.ratingFrom || undefined}
        min={1}
        max={5}
        title="Рейтинг"
      />
      <RangeInputs
        onChangeValue={(typeRangeValue: TypeRangeValue, value: number) => {
          onChangeRangeValue(typeRangeValue, value, 'feedbacksCount');
        }}
        toValue={filterValues.feedbacksCountTo || undefined}
        fromValue={filterValues.feedbacksCountFrom || undefined}
        min={0}
        title="Отзывы, шт"
      />

      <div className={sellersFilterBarCn('selected')}>
        <span>Категория</span>
        <Select typeSelectChild={TypeSelectChild.check} activeNames={checkCategoryNames}>
          <SearchInput
            value={searchValueCategory}
            onChange={onChangeSearchCategory}
            onEnterClick={() => {}}
          />
          <div className={sellersFilterBarCn('category-container')}>
            {categories && searchValueCategory === '' && (
              <FilterCategorySelect
                checkedItems={filterValues.categoryIds || []}
                setCheckedItems={(ids: number[]) => {
                  setFilterValues({ ...filterValues, categoryIds: ids });
                }}
                items={categories || []}
              />
            )}
            {filterCategories && searchValueCategory !== '' && (
              <SearchFilterCategorySelect
                checkedItems={filterValues.categoryIds || []}
                setCheckedItems={(ids: number[]) => {
                  setFilterValues({ ...filterValues, categoryIds: ids });
                }}
                items={filterCategories || []}
              />
            )}
          </div>
        </Select>
      </div>

      <div className={sellersFilterBarCn('selected')}>
        <span>Бренд</span>
        <Select
          typeSelectChild={TypeSelectChild.check}
          activeNames={selectBrands.map((item: BrandDto) => item.name || '')}
        >
          <SearchInput
            value={searchValueBrand}
            onChange={onChangeSearchBrand}
            onEnterClick={() => {}}
          />
          <CheckGroup
            items={(brands || [])
              .concat(selectBrands)
              .map((item: BrandDto) => new SelectItem(item.id || -1, item.name || ''))}
            checkMarkPosition={CheckMarkPosition.left}
            activeIds={filterValues.brandIds || []}
            onChange={(ids: number[]) => {
              setSelectBrands(brands.filter((br: BrandDto) => ids.includes(br.id || -1)));
              setFilterValues({ ...filterValues, brandIds: ids });
            }}
          />
        </Select>
      </div>
    </div>
  );
};
