
import { API } from '../configs/api';
import { linksUrl } from '../consts/linksUrl';
import { Country } from '../view-models/country';

class DictionaryService {
  async getCountries() {
    try {
      let res = API.fields.getCountriesCreate();
      let result = (await res).data;
      return (result as Country[]) || []; 
    } catch (err: any) {
      console.log(err);
      return [];
    }
  }

  async getCRMLink() {
    try {
      let res = API.crm.loginCrmList();
      let result = (await res).data;
     
      return (result.loginUrl as string) || linksUrl.home; 
    } catch (err: any) {
      console.log(err);
      return linksUrl.home;
    }
  }
}

export const dictionaryService: DictionaryService = new DictionaryService();
