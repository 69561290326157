import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { linksUrl } from '../../consts/linksUrl';
import { profileService } from '../../services/profile-service';
import { ReactComponent as FavoriteIcon } from '../../shared/image/svg/favorite.svg';
import { ReactComponent as ProfileIcon } from '../../shared/image/svg/profile.svg';
import { RootState, typedDispatch } from '../../store';
import { setPhoto } from '../../store/profile-image/action';
import { SearchInput } from '../search-input/search-input';
import './header.scss';
import { isOnlyDigit } from '../../utils/isOnlyDigit';

export const Header = () => {
  const photo: string = useSelector((state: RootState) => state.profileImageReducer)?.profilePhoto;
  useEffect(() => {
    photo === null &&
      profileService.getPhoto().then((res: any) => {
        typedDispatch(setPhoto(res.base64));
      });
  }, [1]);

  const [searchValue, setSearchValue] = useState('');

  const onClickEnter = () => {
    if (isOnlyDigit(searchValue)) {
      window.location.href = linksUrl.product + '/' + searchValue
    } else {
      window.location.href =
        linksUrl.analyitcs +
        linksUrl.analyticsProduct +
        '?limit=50&offset=0&desc=false&name=' +
        searchValue;
    }
  };
  return (
    <header>
      <div>
        <SearchInput
          value={searchValue}
          onEnterClick={onClickEnter}
          onChange={setSearchValue}
          placeholder="Поиск по товарам"
        />
      </div>
      <div>
        {/* <Link to={linksUrl.profileNotices}>
          <NotifyIcon />
        </Link> */}
        <Link to={linksUrl.favorite}>
          <FavoriteIcon />
        </Link>
        <Link to={linksUrl.profile + linksUrl.profileInfo}>
          {photo === null ? <ProfileIcon /> : <img src={'data:image/jpeg;base64, ' + photo} />}
        </Link>
      </div>
    </header>
  );
};
